import React, { useState, useEffect } from 'react';
import { Combobox, comboboxFilterAndLimit, Dropdown } from '@salesforce/design-system-react';
import { ArtifactsApiServiceFactory } from "../../../services/sam/factory";

interface CommitSelectorProps {
    setArtifact: React.Dispatch<React.SetStateAction<string>>;
    disable: boolean;
    prefix: string;
}

const ThreeMonths: number = 180
const ArtifactSelector: React.FC<CommitSelectorProps> = ({
                                                             setArtifact,
                                                             disable, prefix
                                                         }) => {
    const [selectedArtifact, setSelectedArtifact] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [filteredArtifacts, setFilteredArtifacts] = useState<{ id: string; label: string }[]>([]);
    const [isFetchingArtifacts, setIsFetchingArtifacts] = useState<boolean>(false);
    const [artifacts, setArtifacts] = useState<string[]>([]);
    const [error, setError] = useState<string>(''); // Error state

    const artifactApiService = ArtifactsApiServiceFactory.getService();

    useEffect(() => {
        fetchStackSetsArtifacts();
    }, []);

    // Effect to filter commits whenever searchTerm or commits change
    useEffect(() => {
        const filtered = artifacts
            .filter((artifact) => artifact.toLowerCase().includes(searchTerm.toLowerCase()))
            .map((commit) => ({ id: commit, label: commit }));
        setFilteredArtifacts(filtered);
    }, [searchTerm, artifacts, setArtifacts]);

    const fetchStackSetsArtifacts = async () => {
        try {
            // Reset state before starting the fetch process
            setError('');
            setArtifacts([]);
            setSearchTerm('');
            setArtifact('');
            setSelectedArtifact('');
            setIsFetchingArtifacts(true);

            let allArtifacts: string[] = [];
            let currentMarker = "";
            let isFetching = true;

            while (isFetching) {
                // Fetch 3 pages of artifacts at a time
                const response = await artifactApiService.getArtifacts(prefix, 1, ThreeMonths, currentMarker);

                if (response && Array.isArray(response.artifactNames)) {
                    // Append the new artifacts to the existing list
                    allArtifacts = [...allArtifacts, ...response.artifactNames];
                    setArtifacts(allArtifacts);
                }

                // Check if response and marker exist
                if (response && response.marker !== undefined) {
                    currentMarker = response.marker;
                }

                // Stop fetching if marker is empty
                if (!currentMarker) {
                    isFetching = false;
                }
            }
        } catch (err: any) {
            console.error('Failed to load artifacts.', err);

            // Set a meaningful error message
            let mssg = 'Failed to load artifacts. Please try again later.';
            if (err && typeof err.message === 'string') {
                mssg = err.message;
            }
            setError(mssg);
        } finally {
            setIsFetchingArtifacts(false);
        }
    };


    return (
        <>
            <div className="slds-size_1-of-1">
                <Combobox
                    id="artifact_picker_combobox"
                    className={""}
                    events={{
                        onSelect: (event: any, data: any) => {
                            if (data.selection && data.selection.length > 0) {
                                setArtifact(data.selection[0].id);
                                setSelectedArtifact(data.selection[0].id);
                                setSearchTerm(data.selection[0].label);
                            }
                        },
                        onChange: (event: any, data: any) => {
                            setSearchTerm(data.value);
                        },
                        onRequestRemoveSelectedOption: (event: any, data: any) => {
                            setArtifact('');
                            setSelectedArtifact('');
                            setSearchTerm('');
                        },
                    }}
                    labels={{
                        placeholder: 'Search by Commit ID or Artifact Name',
                        noOptionsFound: isFetchingArtifacts ? '' : 'No Matches Found.',
                    }}
                    required
                    menuPosition="absolute"
                    options={
                        isFetchingArtifacts
                            ? []
                            : comboboxFilterAndLimit({
                                inputValue: searchTerm,
                                options: filteredArtifacts,
                                selection: selectedArtifact ? [{ id: selectedArtifact, label: selectedArtifact }] : [],
                                limit: 40000,
                            })
                    }
                    value={searchTerm}
                    selection={selectedArtifact ? [{ id: selectedArtifact, label: selectedArtifact }] : []}
                    variant="inline-listbox"
                    menuItemVisibleLength={5}
                    hasMenuSpinner={isFetchingArtifacts}
                    disabled={disable}
                />
                {/* Error message below Combobox */}
                {error && <div className="slds-text-color_error slds-m-top_xx-small">{error}</div>}
            </div>
        </>
    );
};

export default ArtifactSelector;
